import React from 'react'
import Slider from "react-slick"
import Img from 'gatsby-image'

import "slick-carousel/slick/slick.css";
import '~/styles/ProductCardSlider.scss'

const ProductCardSlider = ({ product }) => {
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};

	return (
		<Slider className="product-card-images-slider" {...settings}>
			{product.frontmatter.images.map(image => (
				<div className="product-card-images-slider-item">
					<Img
						className="product-card-images-slider-item-image"
						fluid={image.childImageSharp.fluid}
						alt={product.frontmatter.title}
						imgStyle={{ objectFit: "contain" }}
					/>
				</div>
			))}
		</Slider>
	);
}

export default ProductCardSlider
